import { Navigate, Route, Routes } from 'react-router-dom';

import { AppFooter } from 'components/AppFooter';
import { AppHeader } from 'components/AppHeader';
import { PrivacyPolicy } from 'components/PrivacyPolicy';
import { PRAETURA_URL } from '../constants';

function App() {
  return (
    <div>
      <AppHeader />
      <Routes>
        <Route Component={PrivacyPolicy} path={`/privacy-policy`} />
        <Route path='*' element={<Navigate replace to={`/privacy-policy`} />} />
        <Route
          path='/'
          Component={() => {
            window.location.href = PRAETURA_URL;
            return null;
          }}
        />
      </Routes>
      <AppFooter />
    </div>
  );
}

export default App;
