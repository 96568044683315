import { PraeturaLendingLogo } from 'images';
import styles from './appHeader.styles.module.css';

const AppHeader = (): JSX.Element => (
  <header className={styles.header}>
    <a href='./'>
      <img src={PraeturaLendingLogo} alt='Praetura Lending logo' className={styles.headerLogo} />
    </a>
  </header>
);

export default AppHeader;
