import styles from "./privacyPolicy.styles.module.css";

const PrivacyPolicy = () => {
  return (
    <div className={styles.content}>
      <>
        <h1 className={styles.pageHeader}>
          Privacy Policy for Praetura Lending
        </h1>
        <h2 className={styles.sectionHeader}>Navigation</h2>
        <nav className={styles.navigation}>
          <ol className={styles.navigation}>
            <li>
              <a className={styles.link} href="/privacy-policy#who-we-are">
                1. Who we are.
              </a>
            </li>
            <li>
              <a className={styles.link} href="/privacy-policy#how-to-contact">
                2. How to contact Praetura Group about your personal
                information?
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#scope-of-this-privacy-policy-and-changes"
              >
                3. Scope of this Privacy Policy and changes.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#personal-information-we-collect-about-you"
              >
                4. Personal information we collect about you.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#sources-of-personal-information"
              >
                5. Sources of personal information.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#what-do-credit-reference"
              >
                6. What do credit reference and fraud prevention agencies do
                with your personal information?
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#how-we-use-your-personal-information"
              >
                7. How we use your personal information.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#storage-of-your-personal-information"
              >
                8. Storage of your personal information.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#disclosure-of-your-personal-information"
              >
                9. Disclosure of your personal information.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#change-of-purpose"
              >
                10. Change of Purpose.
              </a>
            </li>
            <li>
              <a
                className={styles.link}
                href="/privacy-policy#what-are-your-rights"
              >
                11. What are your rights?
              </a>
            </li>
            <li>
              <a className={styles.link} href="/privacy-policy#complaints">
                12. Complaints.
              </a>
            </li>
          </ol>
        </nav>
        <h3 id="who-we-are" className={styles.sectionHeader}>
          1 Who we are
        </h3>
        <p className={styles.paragraph}>
          1.1 Praetura Lending operate under a number of group companies of
          trading names which reflect the different services and products we
          have available for our customers. These include:
        </p>

        <table className={styles.table}>
          <thead>
            <tr>
              <td>Company</td>
              <td>Address</td>
              <td>ICO registration number</td>
              <td>Contact email (Data compliance queries)</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Praetura Debt Services Limited (t/a Praetura Lending)</td>
              <td>Ewood House, Walker Park, Walker Road, Blackburn, BB1 2QE</td>
              <td>ZB159946</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
            <tr>
              <td>Praetura Asset Finance Limited</td>
              <td>Ewood House, Walker Park, Walker Road, Blackburn, BB1 2QE</td>
              <td>ZA020984</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
            <tr>
              <td>Praetura Commercial Finance Limited</td>
              <td>Barons Court, Manchester Road, Wilmslow, Cheshire, SK9 1BQ</td>
              <td>ZA212131</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
            <tr>
              <td>Praetura Invoice Finance Limited</td>
              <td>Barons Court, Manchester Road, Wilmslow, Cheshire, SK9 1BQ</td>
              <td>ZB076698</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
            <tr>
              <td>Kingsway Asset Finance Limited</td>
              <td>Barons Court, Manchester Road, Wilmslow, Cheshire, SK9 1BQ</td>
              <td>Z6276029</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
            <tr>
              <td>Zodeq Ltd</td>
              <td>1 The Exchange St. John Street Chester CH1 1DA</td>
              <td>ZA234491</td>
              <td>dpo@praetura.co.uk</td>
            </tr>
          </tbody>
        </table>

        <p className={styles.paragraph}>
          1.2 When we refer to&nbsp;
          <strong className={styles.paragraphBold}>
            “Praetura Lending”, “We”, “our” and “us”&nbsp;
          </strong>
          in this Policy we are referring to each of these trading names
          collectively and individually, unless otherwise stated.
        </p>

        <p className={styles.paragraph}>
          1.3 We are committed to protecting and respecting your privacy. We
          will only process or otherwise disclose your personal information as
          described in this Policy.
        </p>

        <p className={styles.paragraph}>
          1.4 For the purpose of the UK GDPR and the Data Protection Act 2018,
          the Data Protection, Privacy and Electronic Communications (Amendments
          etc) (EU Exit) Regulations 2019, Praetura Lending will be the data
          controller for your personal information, as set out in this Policy.
        </p>

        <h3 id="how-to-contact" className={styles.sectionHeader}>
          2. How to contact Praetura Lending about your personal information?
        </h3>
        <p className={styles.paragraph}>
          2.1 Questions, comments, issues and requests regarding this privacy
          policy or your personal information, including when exercising your
          data rights, are welcomed and should be addressed to&nbsp;
          <a className={styles.link} href="mailto:dpo@praetura.co.uk">
            dpo@praetura.co.uk.
          </a>
        </p>

        <h3
          id="scope-of-this-privacy-policy-and-changes"
          className={styles.sectionHeader}
        >
          3. Scope of this Privacy Policy and changes
        </h3>
        <p className={styles.paragraph}>
          3.1 This Policy aims to give you information on how we collect and
          process your personal information (also known as personal data under
          data protection laws) through your use of our websites (see 3.2),
          including any personal information you may provide when receiving our
          services or funding products, applying for our services or funding
          products, being introduced for funding (broking), when registering
          with us and completing due diligence; when your employer is a customer
          of ours; when using our websites or when you communicate with us; when
          you sign up to any newsletter or email list; or when you enquire about
          our services or funding products. This policy also sets out when we
          may communicate with you in relation to our services, funding products
          and business, as well as the personal information we need to process
          in order to provide our services and products.
        </p>

        <p className={styles.paragraph}>
          3.2 As with all our policies, we may revise and update this Policy
          from time to time, and any updates will be placed on:
        </p>
        <ul className={styles.navigation}>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://praeturalending.co.uk/privacy-policy"
            >
              praeturalending.co.uk/privacy-policy
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.praeturaif.com"
            >
              www.praeturaif.com
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.praeturacf.com"
            >
              www.praeturacf.com
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.praeturaaf.com"
            >
              www.praeturaaf.com
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.kingswayfinance.co.uk"
            >
              www.kingswayfinance.co.uk
            </a>
          </li>
          <li>
            <a
              className={styles.link}
              target="_blank"
              rel="noreferrer"
              href="https://www.zodeq.com"
            >
              www.zodeq.com
            </a>
          </li>
        </ul>

        <p className={styles.paragraph}>
          Please refer to these websites regularly to check any changes and
          updates to this Policy.
        </p>

        <p className={styles.paragraph}>
          3.3 These websites are collectively and individually referred to
          throughout this document as “the website”. The website is not intended
          for children and we do not knowingly collect personal information
          relating to children.
        </p>

        <h3
          id="personal-information-we-collect-about-you"
          className={styles.sectionHeader}
        >
          4. Personal information we may collect from you
        </h3>
        <p className={styles.paragraph}>
          4.1 We may collect and process the following personal information
          about you, including from third parties or public available sources:
        </p>
        <ul className={styles.list}>
          <li>
            your personal details (including name, date of birth, current and
            previous postal addresses and national insurance number);
          </li>
          <li>
            your contact information (including phone and e-mail details);
          </li>
          <li>identity documentation relating to a funding application;</li>
          <li>
            Biometric face mapping (facial recognition) for likeness checking as
            part of KYC;
          </li>
          <li>
            for borrowers, those representing borrowers, and relevant investors,
            your business name and contact information, and borrowing history;
          </li>
          <li>
            financial information (including bank or building society account
            details and details of debit cards used to make transfers);
          </li>
          <li>
            information you provide in our registration or application processes
            (including, if you are a borrower, Agent or relevant investor,
            certain personal information, identity verification, contact details
            and financial information about directors, partners, members,
            shareholders, beneficial owners and guarantors);
          </li>
          <li>
            information about your business or company, such as previous credit
            applications and the conduct of your accounts, and similar personal
            credit information;
          </li>
          <li>open banking information and connection;</li>
          <li> credit reference checks;</li>
          <li> electoral register information;</li>
          <li> fraud prevention information; </li>
          <li>
            politically exposed person, adverse media and sanctions checks;
          </li>
          <li>
            information on High Court filings, County Court Judgements,
            bankruptcy and repossessions;
          </li>
          <li>passwords and answers to security questions;</li>
          <li>
            information about any device you have used to access our services or
            the websites, (such as your device’s make and model, device
            identifier, operating system, browser, MAC address or IP address);
          </li>
          <li>
            information about the pages or sections you have visited on the
            websites including the pages or sections you visited, the website or
            mobile application you were referred from, and when you visited or
            used them;
          </li>
          <li>
            information about the services or funding we provide to you
            (including for example, what we have provided to you, when and where
            and, if applicable, how much you paid);
          </li>
          <li>
            information you provide to us with when you contact us by phone,
            email, post, when you visit one of our offices or when you
            communicate with us via social media;
          </li>
          <li>
            information about electronic communications you receive from us,
            including whether that communication has been opened and if you have
            clicked on any links within that communication;
          </li>
          <li>
            other personal information which you may disclose to us when you use
            our services at any time.
          </li>
        </ul>
        <p className={styles.paragraph}>
          4.2 We also collect, use, retain and share anonymous or aggregated
          personal information ‘Aggregated Data’ such as statistical or
          demographic data/information for any purpose. Aggregated Data could be
          derived from your personal information but is not considered personal
          information in law as this information will not directly or indirectly
          reveal your identity. For example, we may aggregate data/information
          on how you use our Website to calculate the percentage of users
          accessing a specific website feature, or to better understand how our
          customers engage with our funding products or services. However, if we
          combine or connect Aggregated Data with your personal information so
          that it can directly or indirectly identify you, we treat the combined
          data as personal information which will be used in accordance with
          this Policy.
        </p>
        <p className={styles.paragraph}>
          4.3. Sensitive Data (known as Special Category Personal Data and
          includes details about race or ethnicity, religious or philosophical
          beliefs, sex life, sexual orientation, political opinions, trade union
          membership, information about your health, and genetic and biometric
          data). We may collect biometric data about you when conducting
          identity verification, however, such personal data is stored securely
          by our trusted third-party provider, not shared with us, and is
          deleted within 14 days of verification. Otherwise, we do not usually
          process sensitive data about you. If we ever want to process sensitive
          data for ad hoc activities, we will obtain your consent at that time.
        </p>

        <h3
          id="sources-of-personal-information"
          className={styles.sectionHeader}
        >
          5. Sources of personal information
        </h3>
        <p className={styles.paragraph}>
          5.1 We will receive your personal information when you provide them to
          us yourself.
        </p>

        <p className={styles.paragraph}>
          <strong className={styles.paragraphBold}>
            Direct interactions.&nbsp;
          </strong>
          You may give us your Profile, Identity, Contact and Financial Data by
          filling in forms or by corresponding with us by post, phone, email,
          social media or otherwise. This includes personal information you
          provide when you, or the organisation you work within or act on behalf
          of:
        </p>
        <ul className={styles.list}>
          <li>receive our services and/or funding products;</li>
          <li>
            make payments and other financial transactions related to your
            funding products;
          </li>
          <li>
            undergo customer due diligence including but not limited to know
            your customer (“KYC”) checks;
          </li>
          <li>Agree to be a warrantor/guarantor or indemnifier;</li>
          <li>create an account for one of our services;</li>
          <li>
            act as a witness to any legal document signed in connection with our
            services and/or funding products;
          </li>
          <li>connect us with your open banking;</li>
          <li>enquire about or discuss our services and/or products;</li>
          <li>subscribe to our newsletter or mailing list;</li>
          <li>request marketing to be sent to you;</li>
          <li>engage with us via social media and other channels;</li>
          <li>respond to a survey or provide feedback.</li>
        </ul>

        <p className={styles.paragraph}>
          <strong className={styles.paragraphBold}>
            Automated technologies or interactions.&nbsp;
          </strong>
          As you interact with our website, we will automatically collect
          Technical Data about your equipment, browsing actions and patterns. We
          collect this personal information by using cookies, server logs and
          other similar technologies. Please see our cookie policy hosted on the
          website for further details.
        </p>

        <p className={styles.paragraph}>
          <strong className={styles.paragraphBold}>
            Third parties or publicly available sources.&nbsp;
          </strong>
          We will receive personal information about you from various third
          parties and public sources as set out below.
        </p>

        <ul className={styles.list}>
          <li>Your employer/organisation;</li>
          <li>
            A family member when they have been appointed or notified to us as a
            point of contact;
          </li>
          <li>Your broker or agent;</li>
          <li>
            Our network of third-party finance partners in respect of making or
            receiving referrals;
          </li>
          <li>
            Banks, financial institutions and payment processing facilities;
          </li>
          <li>Guarantors/warrantors/indemnifiers;</li>
          <li>
            Credit reference agencies, conducting commercial credit checks,
            quotation searches, consumer credit searches, and other financial
            suitability checks, as well as ongoing communications and updates
            (more detail is provided in section 6 below);
          </li>
          <li>Companies House;</li>
          <li>Third-party references obtained with your agreement;</li>
          <li>Our industry regulators and Government bodies;</li>
          <li>
            Fraud prevention agencies (including CIFAS), the police, government
            agencies, and other law enforcement agencies;
          </li>
          <li>Technical Data from analytics providers such as Google</li>
          <li>
            Contact, Identity, Financial and Transaction Data from providers of
            technical, payment and delivery services;
          </li>
        </ul>

        <h3 id="what-do-credit-reference" className={styles.sectionHeader}>
          6. What do credit reference and fraud prevention agencies do with your
          personal information?
        </h3>
        <p className={styles.paragraph}>
          The information in this section 6 is relevant if you are a borrower,
          having taken out one of our funding products or are applying for one,
          are a director or officer of or are a guarantor, warrantor or
          indemnifier or are the Ultimate Beneficial Owner (“UBO”) of a
          borrower, or we otherwise need to perform a credit check against in
          relation to applying for or administering a funding product.
        </p>

        <p className={styles.paragraph}>
          6.1 In order to process your application, we will perform credit and
          identity checks on you with one or more Credit Reference Agencies
          (“CRAs”). We may also make periodic searches at CRAs to manage your
          account with us.
        </p>

        <p className={styles.paragraph}>
          6.2 To do this, we will supply your personal information to CRAs and
          they will give us information about you. This will include information
          from your credit application and about your financial situation and
          financial history. CRAs will supply to us both public (including the
          electoral register) and shared credit, financial situation and
          financial history information and fraud prevention information.
        </p>

        <p className={styles.paragraph}>6.3 We will use this information to:</p>

        <p className={styles.paragraph}>
          (a) assess your creditworthiness and whether you can afford to take
          the funding product;
        </p>
        <p className={styles.paragraph}>
          (b) assess your creditworthiness and whether you are in a position to
          provide a guarantee;
        </p>
        <p className={styles.paragraph}>
          (c) verify the accuracy of the information you have provided to us;
        </p>
        <p className={styles.paragraph}>
          (d) prevent criminal activity, fraud and money laundering;
        </p>
        <p className={styles.paragraph}>(e) manage your account(s);</p>
        <p className={styles.paragraph}>(f) trace and recover debts; and</p>
        <p className={styles.paragraph}>
          (g) ensure any offers provided to you are appropriate to your
          circumstances.
        </p>

        <p className={styles.paragraph}>
          6.4 We will continue to exchange information about you with CRAs while
          you have a relationship with us. We will also inform the CRAs about
          your settled accounts. If you borrow and do not repay in full and on
          time, CRAs will record the outstanding debt. This information may be
          supplied to other organisations by CRAs.
        </p>
        <p className={styles.paragraph}>
          6.5 When CRAs receive a search from us they will place a search
          footprint on your credit file that may be seen by other lenders.
        </p>
        <p className={styles.paragraph}>
          6.6 If you are making a joint application, or tell us that you have a
          spouse or financial associate, we will link your records together, so
          you should make sure you discuss this with your spouse and share with
          them this information, before lodging the application. CRAs will also
          link your records together and these links will remain on your and
          their files until such time as you or your partner successfully files
          for a disassociation with the CRAs to break that link. This means that
          the borrowing and address history of associated persons will
          automatically be checked by CRAs, whether this is disclosed by you or
          not.
        </p>
        <p className={styles.paragraph}>
          6.7 The identities of the CRAs, their role also as fraud prevention
          agencies, the data they hold, the ways in which they use and share
          personal information, data retention periods and your data protection
          rights with the CRAs are explained in more detail at&nbsp;
          <a
            className={styles.link}
            href="http://www.experian.co.uk/crain/index.html"
            target="_blank"
            rel="noreferrer"
          >
            http://www.experian.co.uk/crain/index.html
          </a>
          .
        </p>

        <h3
          id="how-we-use-your-personal-information"
          className={styles.sectionHeader}
        >
          7. How we use your personal information
        </h3>
        <p className={styles.paragraph}>
          7.1 All personal information that we obtain about you will be used in
          accordance with current data protection law and this Privacy Policy.
          We will process your personal information as follows:
        </p>

        <ul className={styles.list}>
          <li>
            As necessary, to meet our obligations under any contracts we are a
            party to (or your employer is a party to), such as a contract to
            lend or provide funding under a funding product, including, where
            applicable, opening accounts, transferring money and carrying out
            support services.
          </li>
          <li>
            As necessary, to comply with a legal obligation, for the following
            purposes:
            <ul className={styles.list}>
              <li>
                Where you exercise your rights under data protection law and
                make requests.
              </li>
              <li> To report to our regulators.</li>
              <li> To provide and maintain tax and accounting records.</li>
              <li>
                To prevent criminal activity, money laundering and fraud
                (including conducting Know-Your-Client checks)
              </li>
            </ul>
          </li>
          <li>
            Based on your consent, for the following purposes:
            <ul className={styles.list}>
              <li>
                To conduct credit checks and other due diligence associated with
                funding product applications;
              </li>
            </ul>
          </li>
        </ul>

        <p className={styles.paragraph}>
          Based on Legitimate Interest. We may process your personal information
          for the purposes of our legitimate interests, provided that these uses
          aren’t outweighed by your rights or interests. For any uses we justify
          on the basis of legitimate interest, you have the right to opt out of
          such processing. These include the following:
        </p>
        <ul className={styles.list}>
          <li>To conduct general credit and account management.</li>
          <li>To trace and recover debts.</li>
          <li>
            We may contact you for marketing purposes (see 7.2 Marketing).
          </li>
          <li> To prevent fraud and money laundering.</li>
          <li>
            We may review and assess your use of our services in order to better
            understand our business and services, as well as develop new
            products.
          </li>
          <li>
            To contact you in relation the performance of services to you or
            your employer.
          </li>
          <li> To manage our accounts and tax reporting.</li>
          <li>
            To manage and support our customer relationship management and
            business functions, supply chain and subcontractors.
          </li>
          <li>
            To operate and maintain our website and, to provide updates and new
            versions and ensure their performance.
          </li>
          <li>
            To ascertain your borrowing needs and to periodically assess your
            eligibility for other products we offer which may be relevant for
            you.
          </li>
          <li>
            To obtain and enforce personal guarantees, warranties or
            indemnities.
          </li>
          <li> To administer our products and services.</li>
          <li>
            To review our use of personal information to ensure it is compliant.
          </li>
        </ul>

        <p className={styles.paragraph}>7.2 Marketing</p>
        <ul className={styles.list}>
          <li>
            If you are an existing customer or introducer, or work for one, we
            will use your personal information to communicate with you about
            similar products or services which we think may be of interest to
            you. We may also market to you if you work for an organisation which
            we believe will be interested in our products and services.
          </li>
          <li>
            We will not send you marketing communications if you have opted out
            or if you have unsubscribed from marketing. You will be able to
            opt-out of such communications at any time by clicking the
            unsubscribe link or contacting us using the contact details in
            section 1.1 of this policy. Where we consider it necessary, we will
            ask for your express consent before sending you any marketing email.
          </li>
          <li>
            We do not share your personal information for third party marketing
            without your consent.
          </li>
        </ul>
        <p className={styles.paragraph}>7.3 Links:</p>
        <p className={styles.paragraph}>
          Our Website may, from time to time, contain links to and from the
          websites of our partners. If you follow a link to any of these
          websites, please note that these websites have their own privacy
          policies and that we do not accept any responsibility or liability for
          these policies. Please check these policies before you submit any
          personal information to these websites.
        </p>
        <h3
          id="storage-of-your-personal-information"
          className={styles.sectionHeader}
        >
          8. Storage of your personal information
        </h3>
        <p className={styles.paragraph}>
          8.1 Praetura Lending is a UK based group of organisations whose
          offices are in the UK.
        </p>
        <ul className={styles.list}>
          <li> Our websites are hosted within the UK;</li>
          <li>
            Our customer relationship management, marketing, digital signing,
            Open Banking, Identity Verification, servicing, and accounting
            systems for our businesses are UK and EEA (European Economic Area)
            based.
          </li>
          <li>
            We use Amazon Web Services (“AWS”) and Microsoft Cloud Services
            Azure as part of our processing environment and utilise UK and EEA
            located processing facilities.
          </li>
        </ul>
        <p className={styles.paragraph}>
          Our payment processors and banking arrangements are based in UK and
          EEA.
        </p>
        <p className={styles.paragraph}>
          8.2 We may transfer personal information outside of the UK or EEA in
          limited circumstances. We will only do this if we can ensure a similar
          degree of protection (as given under UK GDPR) is afforded to the
          personal information by ensuring at least one of the following
          safeguards apply:
        </p>
        <ul className={styles.list}>
          <li>
            We will only transfer your personal information to countries that
            have been deemed to provide an adequate level of protection for
            personal information by the UK Government (please note, this is
            deemed to include the EEA post Brexit).
          </li>
          <li>
            Where we use certain service providers, we may use specific
            contracts approved by the UK Government and the ICO which give
            personal information the same protection it has in UK. We will also
            undertake appropriate due diligence and risk assessment of that
            service provider and local laws.
          </li>
          <li>
            Where we transfer personal information to the USA we may rely on the
            ‘Data Bridge’ arrangement which allows the sharing of personal
            information to US companies approved under the European Data
            Protection Framework.
          </li>
        </ul>

        <p className={styles.paragraph}>
          8.3 We may also transfer your personal information outside of UK or
          EEA without one of the above safeguards (9.2) on an occasional basis
          with your consent or to perform a contract with you.
        </p>
        <p className={styles.paragraph}>
          8.4 The transmission of information via the Internet is not completely
          secure. Although we will do our best to protect your personal
          information, we cannot guarantee the security of your data transmitted
          to us via our website or communication channels. Any transmission is
          therefore at your own risk. Once we have received your information, we
          will use strict procedures and security features to try to prevent
          unauthorised access.
        </p>
        <h3
          id="disclosure-of-your-personal-information"
          className={styles.sectionHeader}
        >
          9. Disclosure of your personal information
        </h3>
        <p className={styles.paragraph}>
          9.1 We may disclose your personal information to any member of
          Praetura Lending.
        </p>
        <p className={styles.paragraph}>
          9.2 We may disclose your personal information to third parties:
        </p>
        <ul className={styles.list}>
          <li>
            In the event that we sell or buy any business or assets, in which
            case we may disclose your personal information to the prospective
            seller or buyer of such business or assets.
          </li>
          <li>
            If our business or substantially all of its assets is acquired by a
            third party, in which case personal information held by us about our
            customers will be one of the transferred assets.
          </li>
          <li>
            If we are under a duty to disclose or share your personal
            information in order to comply with any legal obligation, or in
            order to enforce or apply our terms and conditions and other
            agreements with you.
          </li>
          <li>
            To protect the rights, property, or safety of us, our customers, or
            others.
          </li>
          <li> To fraud prevention agencies and the police.</li>
          <li> To credit reference agencies.</li>
          <li> With our third party payment processors and banks</li>
          <li> To your broker or agent.</li>
          <li> To debt collection or enforcement agencies.</li>
          <li> To our professional advisors.</li>
          <li> When connecting and interacting via Open-Banking.</li>
          <li>
            When carrying out identity verification and AML checks via a 3rd
            party.
          </li>
          <li> When issuing documents to be signed digitally. </li>
          <li>
            In relation to our IT support provision, AWS environment and other
            cloud services.
          </li>
          <li>
            In relation to our business support services, including marketing
            and customer relationship management platforms
          </li>
          <li>
            If we need to disclose personal information to one of our suppliers
            who may be providing the services or products directly to you,
            however, please note we ensure we have appropriate safeguards in
            place with these suppliers.
          </li>
          <li> To other third parties with your prior consent.</li>
        </ul>
        <h3 id="change-of-purpose" className={styles.sectionHeader}>
          10. Change of purpose
        </h3>
        <p className={styles.paragraph}>
          We will only use your personal information for the purposes for which
          we collected it, unless we reasonably consider that we need to use it
          for another reason and that reason is compatible with the original
          purpose.
        </p>
        <p className={styles.paragraph}>
          If we need to use your personal information for an unrelated purpose,
          we will notify you and we will explain the legal basis which allows us
          to do so.
        </p>
        <h3 id="what-are-your-rights" className={styles.sectionHeader}>
          11. What are your rights?
        </h3>

        <p className={styles.paragraph}>
          11.1 Praetura Lending recognises that your personal information
          belongs to you and we don’t wish to use it in ways that you don’t want
          us to.
        </p>
        <p className={styles.paragraph}>
          11.2 Please note that you have specific rights under data protection
          law in relation to the use of your personal information, including the
          ability to regulate and/or object to our use of your personal
          information.
        </p>
        <p className={styles.paragraph}>
          11.3 You have the right to object to our processing of your personal
          information in some circumstances, including where:
        </p>
        <ul className={styles.list}>
          <li> Processing is based on legitimate interest;</li>
          <li> Processing is for the purpose of direct marketing;</li>
          <li> Processing involves automated decision-making and profiling.</li>
        </ul>
        <p className={styles.paragraph}>
          11.4 You can also exercise a variety of rights regarding our use of
          your personal information:
        </p>

        <ul className={styles.list}>
          <li>
            You can ask us for a copy of the information we have about you
            (usually free of charge)
          </li>
          <li>
            You can request an explanation of decisions regarding the processing
            of your information.
          </li>
          <li>
            You can ask us to correct any incorrect personal information we have
            about you by contacting us via the details above.
          </li>
          <li>
            You can ask us to delete your personal information where it is no
            longer necessary for us to use it, where you have withdrawn consent,
            or where we have no lawful basis for keeping it.
          </li>
          <li>
            You can ask for your personal information in a common,
            machine-readable format.
          </li>
          <li>
            You can object to any processing we do on the basis of legitimate
            interests or to any automated decision-making, for example when your
            employer shares your details with us.
          </li>
          <li>
            object to us processing your personal information for direct
            marketing purposes even if you have previously given consent.
          </li>
        </ul>

        <p className={styles.paragraph}>
          <strong className={styles.paragraphBold}>Please note&nbsp;</strong> we
          will balance any request to assert the above rights with other legal
          requirements and permissions in relation to the processing and
          retention of personal information; for example, the need to protect
          other individual’s personal information, to perform a contract with
          you, to meet a legal reporting requirement or because we have a
          compelling legitimate interest which we have determined to outweigh
          the above rights.
        </p>

        <p className={styles.paragraph}>11.5 What we may need from you</p>

        <p className={styles.paragraph}>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          information (or to exercise any of your other rights). This is a
          security measure to ensure that personal information is not disclosed
          to any person who has no right to receive it. We may also contact you
          to ask you for further information in relation to your request to
          speed up our response.
        </p>

        <p className={styles.paragraph}>11.6 Time limit to respond</p>

        <p className={styles.paragraph}>
          We aim to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </p>
        <p className={styles.paragraph}>11.7. Withdrawing your consent</p>
        <p className={styles.paragraph}>
          Where we are processing your personal information based on your
          consent, you may change your mind and withdraw your consent at any
          time. You can also withdraw any consent you have previously provided
          to us by contacting us using the details in this policy.
        </p>
        <h3 id="complaints" className={styles.sectionHeader}>
          12. Complaints
        </h3>

        <p className={styles.paragraph}>
          12.1 Should you wish to discuss a complaint, please feel free to
          contact us using the details provided above. All complaints will be
          treated in a confidential manner.
        </p>
        <p className={styles.paragraph}>
          12.2 Should you feel unsatisfied with our handling of your personal
          information, or about any complaint that you have made to us about our
          handling of your personal information, you are entitled to escalate
          your complaint to a supervisory authority. For the United Kingdom,
          this is the Information Commissioner’s Office (ICO), who is also our
          lead supervisory authority. Its contact information can be found
          at&nbsp;
          <a
            className={styles.link}
            href="https://ico.org.uk/global/contact-us/"
            target="_blank"
            rel="noreferrer"
          >
            https://ico.org.uk/global/contact-us/
          </a>
        </p>

        <h3 className={styles.sectionHeader}>Policy update</h3>
        <p className={styles.paragraph}>
          This policy was last updated on 22 August 2024.
        </p>
      </>
    </div>
  );
};

export default PrivacyPolicy;
