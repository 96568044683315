import { Link } from 'react-router-dom';
import { PraeturaLendingLogo } from 'images';

import styles from './appFooter.styles.module.css';

const AppFooter = (): JSX.Element => (
  <footer className={styles.footer}>
    <img src={PraeturaLendingLogo} alt='Praetura Lending logo' className={styles.footerLogo} />
    <p className={styles.footerHeader}>Our offices</p>
    <div className={styles.addressDetails}>
      <p className={styles.footerText}>Level 8, Bauhaus</p>
      <p className={styles.footerText}>27 Quay Street</p>
      <p className={styles.footerText}>Manchester</p>
      <p className={styles.footerText}>M3 3GY</p>
    </div>
    <div className={styles.footerLinks}>
      <div className={styles.linkContainer}>
        <Link to='privacy-policy' className={styles.footerLink} target='_blank' rel='noreferrer'>
          PRIVACY POLICY
        </Link>
      </div>
    </div>
    <div className={styles.info}>
      <p className={styles.footerInfoText}>
        Praetura Lending is a trading name of Praetura Debt Services Limited.
      </p>
      <br />

      <p className={styles.footerInfoText}>
        Registered in England and Wales, Company Registration Number 12073847.
      </p>
    </div>
  </footer>
);

export default AppFooter;
